@import "@/scss/_variables.scss";
































































































































.export-profile-teaser {
  position:relative;
  background: var(--vform-editor-ui-secondary-color);
  padding: 5px 10px 8px 10px;
  p {
    z-index: 0;
    position:relative;
  }
}
.profile-edit-button {
  z-index:1;
  position:absolute;
  top:8px;
  right: 8px;
  display:inline-block;
}
